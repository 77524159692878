import { BrowserRouter } from "react-router-dom";
import Highlights from './components/Highlights';
import Model from './components/Model';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import { Footer, About, Contact, Experience, Feedbacks, Hero, Navbar, Works,  YouTubeVideo } from "./components";
import * as Sentry from '@sentry/react';
const App = () => {
  //return <button onClick={() => methodDoesNotExist()}>Break the world</button>;
  return (
    <BrowserRouter>
      <div className='relative z-0 bg-primary'>
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar />
          <Hero />
          {/* <Model /> */}
        </div>
        <Highlights />
        {/* <Works /> */}
      <Features />
      {/* <HowItWorks /> */}
        {/* <About /> */}
        {/* <YouTubeVideo /> */}
        <Experience />
        
        {/* <Feedbacks /> */}
        <div className='relative z-0'>
          <Contact />
          <Footer/>
        </div>
      </div>
    </BrowserRouter>
    
  );
}

export default Sentry.withProfiler(App);
import hero from "/assets/images/heros.jpeg";

export const heroImg = hero;

import hmv from "/assets/videos/heros.mp4";
//import smallmv from "/assets/videos/smallHero.mp4";
import smallmv from "/assets/videos/smallHeros.mp4";
import highlightFirstmv from "/assets/videos/highlight-firsts.mp4";
import highlightSectmv from "/assets/videos/hightlight-thirds.mp4";
import highlightThirdmv from "/assets/videos/hightlight-secs.mp4";
import highlightFourthmv from "/assets/videos/hightlight-fourths.mp4";
import exploremv from "/assets/videos/explores.mp4";
//import framemv from "/assets/videos/frame.mp4";
import framemv from "/assets/videos/Movie_007.mp4";

import apple from "/assets/images/apple.svg";
import search from "/assets/images/search.svg";
import bag from "/assets/images/bag.svg";
import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.jpg";
import otypestar from "/assets/images/o-type_star.png";
import frame from "/assets/images/frame.png";


import orion_infrared from "/assets/images/orion_infrared.png";
import orion_from_antarctica_in_polar_night from "/assets/images/orion_from_antarctica_in_polar_night.png";
import betelgeuse_supernova from "/assets/images/betelgeuse_supernova.svg";

export const heroVideo = hmv;
export const smallHeroVideo = smallmv;
export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;
export const exploreVideo = exploremv;
export const frameVideo = framemv;

export const orionnight = orion_from_antarctica_in_polar_night;
export const infrared = orion_infrared;
export const agi = betelgeuse_supernova;

export const appleImg = apple;
export const searchImg = search;
export const bagImg = bag;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const otype = otypestar;
export const frameImg = frame;
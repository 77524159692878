import {
    customcomedy,
     mobile,
     backend,
     creator,
     web,
  // javascript,
  // typescript,
  // html,
  // css,
  // reactjs,
  // redux,
  // tailwind,
  // nodejs,
  // mongodb,
  // git,
  // figma,
  // docker,
  // meta,
  // starbucks,
  // tesla,
  // shopify,
  // carrent,
  // jobit,
  // tripguide,
  // threejs,
  // vacuum,
  // untitled,
  // opensea,
  // opensealogo,
   //sunset, 
  // keen,
  instagram,
  facebook,
  twitter,
  linkedin,
  tiktok,
} from "../assets";

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  // {
  //   id: "social-media-5",
  //   icon: tiktok,
  //   link: "https://www.tiktok.com/@alursolarpal/",
  // },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/profile.php?id=61550310082626",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/in/khristophe-keen-46270394/",
  },
];

// export const footerLinks = [
//   {
//     title: "Useful Links",
//     links: [
//       {
//         name: "Content",
//         link: "https://www.hoobank.com/content/",
//       },
//       {
//         name: "How it Works",
//         link: "https://www.hoobank.com/how-it-works/",
//       },
//       {
//         name: "Create",
//         link: "https://www.hoobank.com/create/",
//       },
//       {
//         name: "Explore",
//         link: "https://www.hoobank.com/explore/",
//       },
//       {
//         name: "Terms & Services",
//         link: "https://www.hoobank.com/terms-and-services/",
//       },
//     ],
//   },
// ];

export const navLinks = [
  // {
  //   id: "about",
  //   title: "Questions?",
  // },
  {
    id: "work",
    title: "The Process",
  },
  {
    id: "contact",
    title: "Get Started!",
  },
];

const services = [
  {
    title: "Pay Nothing Upfront",
    icon: web,
  },
  {
    title: "Enjoy Fixed Payments",
    icon: mobile,
  },
  {
    title: "Transfer with Ease",
    icon: backend,
  },
  {
    title: "Buyout Options",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    // icon: vacuum,
  },
  {
    name: "CSS 3",
    // icon: tesla,
  },
  {
    name: "JavaScript",
    // icon: starbucks,
  },
  {
    name: "TypeScript",
    // icon: meta,
  },
  {
    name: "React JS",
    // icon: shopify,
  },
  {
    // name: "Redux Toolkit",
    // icon: untitled,
  },
  // {
  //   name: "Tailwind CSS",
  //   icon: tailwind,
  // },
  // {
  //   name: "Node JS",
  //   icon: nodejs,
  // },
  // {
  //   name: "MongoDB",
  //   icon: mongodb,
  // },
  // {
  //   name: "Three JS",
  //   icon: threejs,
  // },
  // {
  //   name: "git",
  //   icon: git,
  // },
  // {
  //   name: "figma",
  //   icon: figma,
  // },
  // {
  //   name: "docker",
  //   icon: docker,
  // },
];

const experiences = [
  {
    title: "Raw Materials Extraction and Manufacturing:",
    //company_name: "Vacuum Tubes",
    //icon: vacuum,
    iconBg: "#383E56",
    date: "First Step",
    points: [
      "This stage involves extracting raw materials such as silicon, glass, metals, and various chemicals needed to manufacture solar panels.",
      "The manufacturing process includes cutting, doping, and assembling these materials into photovoltaic cells, which are then assembled into solar panels.",
    ],
  },
  {
    title: "Transportation and Installation:",
    //company_name: "Ai Eye",
    //icon: tesla,
    iconBg: "#E6DEDD",
    date: "Second Step",
    points: [
      "Once manufactured, solar panels are transported to their installation sites. Installation involves mounting the panels onto rooftops, ground-mounted structures, or other suitable locations where they can capture sunlight effectively.",
      "This stage also includes the installation of supporting equipment such as inverters, wiring, and mounting hardware.",
    ],
  },
  {
    title: "Operation and Maintenance:",
    //company_name: "Ai Winter",
    //icon: starbucks,
    iconBg: "#383E56",
    date: "Third Step",
    points: [
      "During this stage, solar panels generate electricity from sunlight. They typically have a long operational life, often lasting 25 years or more.",
      "Regular maintenance, such as cleaning the panels to remove dirt and debris, as well as monitoring system performance, is essential to ensure optimal energy production.",
    ],
  },
  {
    title: "End-of-Life Disposal or Recycling:",
    //company_name: "Vision",
    //icon: meta,
    iconBg: "#E6DEDD",
    date: "Fourth Step",
    points: [
      "At the end of their operational life, solar panels need to be decommissioned and either disposed of or recycled. Disposal methods can include landfilling or incineration, but these options can pose environmental risks due to the presence of hazardous materials such as lead and cadmium in some panel components.",
      "Recycling offers a more sustainable option by recovering valuable materials like silicon, glass, and metals for reuse in new products.",
    ],
  },
  // {
  //   title: "Graduate",
  //   company_name: "Vision",
  //   //icon: shopify,
  //   iconBg: "#E6DEDD",
  //   date: "2010^s",
  //   points: [
  //     "Deep neural networks demonstrated exceptional performance in image recognition, speech synthesis, and language translation.",
  //     "AI applications began permeating various industries, including healthcare, finance, and transportation.",
  //   ],
  // },
  // {
  //   title: "Post-Graduate",
  //   company_name: "Vision",
  //   //icon: untitled,
  //   //iconBg: "#E6DEDD",
  //   date: "Present",
  //   points: [
  //     "AI continues to advance rapidly. Reinforcement learning, generative models, and AI ethics are active areas of research.",
  //     "AI technologies such as virtual assistants, autonomous vehicles, and recommendation systems are becoming more prevalent, shaping various aspects of society and the economy.",
  //   ],
  // },
];

const testimonials = [
  {
    testimonial:
      "I thought large language models would never work like this, but a 'Generative Pre-trained Transformer' proved me wrong.",
    name: "Khristophe Keen",
    designation: "CEO",
    company: "Portraitstone LLC",
    //icon: keen,
  },
  // {
  //   testimonial:
  //     "I've never met a web developer who truly cares about their clients' success like Rick does.",
  //   name: "Chris Brown",
  //   designation: "COO",
  //   company: "DEF Corp",
  //   image: "https://randomuser.me/api/portraits/men/5.jpg",
  // },
  // {
  //   testimonial:
  //     "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
  //   name: "Lisa Wang",
  //   designation: "CTO",
  //   company: "456 Enterprises",
  //   image: "https://randomuser.me/api/portraits/women/6.jpg",
  // },
];

const projects = [
  // {
  //   name: "Car Rent",
  //   description:
  //     "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
  //   tags: [
  //     {
  //       name: "react",
  //       color: "blue-text-gradient",
  //     },
  //     {
  //       name: "mongodb",
  //       color: "green-text-gradient",
  //     },
  //     {
  //       name: "tailwind",
  //       color: "pink-text-gradient",
  //     },
  //   ],
  //   image: carrent,
  //   source_code_link: "https://github.com/",
  // },
  {
    name: "Find the small red dot upper right! Go MBTI... ",
    description:
      "Take the test and let's find your comedic blindspot!",
    tags: [
      {
        name: "udja app",
        color: "blue-text-gradient",
      },
      // {
      //   name: "LLM",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "gpt",
      //   color: "pink-text-gradient",
      // },
    ],
    image: customcomedy,
    source_code_link: "https://udja.app/",
  },
  // {
  //   name: "non-fungible token",
  //   description:
  //     "(NFTs) redefine investment property by representing unique digital assets with scarcity and verifiable authenticity, offering diverse investment opportunities in the evolving digital economy using a 'Generative Pre-trained Transformer.'",
  //   tags: [
  //     {
  //       name: "agi+",
  //       color: "blue-text-gradient",
  //     },
  //     {
  //       name: "LLM",
  //       color: "green-text-gradient",
  //     },
  //     {
  //       name: "gpt",
  //       color: "pink-text-gradient",
  //     },
  //   ],
  //   //image: opensea,
  //   source_code_link: "https://opensea.io/suunstar/",
  // },
];


import {
  blackImg,
  blueImg,
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  whiteImg,
  yellowImg,
} from "../utils";

//export const navLists = [];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Raw Materials Extraction and Manufacturing:",
      "Silicon Production:",
      "Metal Extraction:",
    ],
    video: highlightFirstVideo,
    videoDuration: 4,
  },
  {
    id: 2,
    textLists: ["Transportation and Installation:", 
    "Loading and Shipping:",
    "Site Preparation:"],
    video: highlightSecondVideo,
    videoDuration: 5,
  },
  {
    id: 3,
    textLists: [
      "Operation and Maintenance:",
      "Inspecting for Damage:",
      "Monitoring System Performance:",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 4,
    textLists: ["End-of-Life Disposal or Recycling: ", 
    "Decommissioning Process:",
    "Disposal Methods:",
  ],
    video: highlightFourthVideo,
    videoDuration: 3.63,
  },
];

export const models = [
  {
    id: 1,
    title: "iPhone 15 Pro in Natural Titanium",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },
  {
    id: 2,
    title: "iPhone 15 Pro in Blue Titanium",
    color: ["#53596E", "#6395ff", "#21242e"],
    img: blueImg,
  },
  {
    id: 3,
    title: "iPhone 15 Pro in White Titanium",
    color: ["#C9C8C2", "#ffffff", "#C9C8C2"],
    img: whiteImg,
  },
  {
    id: 4,
    title: "iPhone 15 Pro in Black Titanium",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  // "Privacy Policy",
  // "Terms of Use",
  // "Sales Policy",
  // "Legal",
  // "Site Map",
];

export const socialLinks = [
  {
    name: 'Instagram',
    //iconUrl: instagram,
    link: 'https://www.instagram.com/keen.khristophe/',
},
    // {
    //     name: 'Contact',
    //     //iconUrl: contact,
    //     link: '/contact',
    // },
    {
        name: 'GitHub',
        //iconUrl: github,
        link: 'https://github.com/khristophekeen',
    },
    {
        name: 'LinkedIn',
        //iconUrl: linkedin,
        link: 'https://www.linkedin.com/in/khristophe-keen-46270394/',
    },

];

export { services, technologies, experiences, testimonials, projects };

